import React from 'react';

const createNewsletter = () => ({
  __html: `<!-- Begin Mailchimp Signup Form -->
<link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css">
<style type="text/css">
	#mc_embed_signup{background:#fff; clear:left; font:14px width:100%;}
	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
  #mc_embed_signup {
    padding: 0rem;
    margin-top: 1rem;
    width: auto;
  }
  #mc_embed_signup .button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  @media screen and (max-width: 768px) {
    #mc_embed_signup input.email {
      margin: 1rem 2rem;
      width: 75%;
    }
    #mc_embed_signup .button {
      margin-left: 2rem;
      margin-right: 2rem;
      width: 75%;
    }
  }
</style>
<div id="mc_embed_signup">
<form action="https://chessvision.us20.list-manage.com/subscribe/post?u=8aabf528ed8ec6e2afc360e74&amp;id=e447123549" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
	<label for="mce-EMAIL">Subscribe to receive updates and new features</label>
	<input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Email address" required>
    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_8aabf528ed8ec6e2afc360e74_e447123549" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>

<!--End mc_embed_signup-->`
})
const Newsletter = () => <div dangerouslySetInnerHTML={createNewsletter()} />;

export default Newsletter;
