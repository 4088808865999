import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { InlineIcon } from '@iconify/react';
import discordIcon from '@iconify-icons/mdi/discord';

import Typing from 'react-typing-animation';

import exampleImg from './assets/example.png';
import avatar from './assets/avatar.png';
import './App.css';
import Newsletter from './Newsletter';
import Footer from './Footer';

import {
  DiscordMessage, DiscordMessages,
  DiscordEmbed, DiscordEmbedFields, DiscordEmbedField,
  DiscordOptionsContext, DiscordDefaultOptions,
} from '@danktuary/react-discord-message';

import theme from './theme';

const discordOptions = {
	...DiscordDefaultOptions,
	profiles: {
		bot: {
			author: 'ChessvisionAiBot',
      bot: true,
			roleColor: '#ff3d00',
      avatar: avatar,
		},
		alice: {
			author: 'Alice',
			avatar: 'https://i.imgur.com/axQ9wJl.png',
		},
		bob: {
			author: 'Bob',
			avatar: 'https://i.imgur.com/0TeacfY.png',
		},
	},
};

const styles = theme => ({
  container: {
    minHeight: '700px',
    marginTop: '2rem',
  },
  homeLink: {
    textDecoration: 'none',
    color: 'inherit !important',
    '&:hover': {
      opacity: 0.75,
    },
  },
  desc: {
    maxWidth: '420px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cta: {
    height: '4rem',
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  ctaContent: {
    alignItems: 'center',
    display: 'flex',
  },
  ctaText: {
    marginLeft: '1rem',
    fontSize: '1.1rem',
  },
  discordLink: {
    marginBottom: '2rem',
  },
  discordContainer: {
    margin: '0 auto',
    maxWidth: '420px',
    maxHeight: '700px',
    borderRadius: '1rem',
    overflow: "hidden",
    backgroundColor: "#36393e",
    boxShadow: "0 .5rem 1rem rgba(0,0,0,.15) !important",
    position: "relative",
  },
  discordHeader: {
    padding: ".5rem 1rem",
    borderBottom: "hsla(0,0%,100%,.2)",
    zIndex: 999,
    position: "absolute",
    backgroundColor: "#36393e",
    width: "100%",
    top: 0,
  },
  featuresList: {
    marginLeft: '1.3rem',
    '&>li': {
      listStyle: 'disc',
      padding: '0.5rem 0',
    },
  },
});

const Spoiler = ({children}) => (
  <span className="spoiler" onClick={(e) => { e.target.classList.add('spoiler-revealed'); }}>
    {children}
  </span>
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawn: 1,
    };
    this.timer = null;
    this.advance = this.advance.bind(this);
    this.messages = [
    	<DiscordMessage profile="alice">
    		<Typing startDelay={1000} onFinishedTyping={this.advance} cursor="|">Hi, can you share the position you told me about?</Typing>
    	</DiscordMessage>,
    	<DiscordMessage profile="bob">
    	  <Typing startDelay={1000} onFinishedTyping={this.advance} cursor="|">
          <div>Sure, there you go</div>
          <img src={exampleImg} style={{ maxWidth: "240px" }} alt="Example position" />
        </Typing>
    	</DiscordMessage>,
    	<DiscordMessage profile="alice">
    	  <Typing startDelay={1000} onFinishedTyping={this.advance} cursor="|">cv.scan</Typing>
    	</DiscordMessage>,
      <DiscordMessage profile="bot">
        <Typing hideCursor speed={100} onFinishedTyping={() => this.advance(0)}>
          <span>● ● ●</span>
          <Typing.Reset count={1} delay={50} />
          <span>● ● ●</span>
          <Typing.Reset count={1} delay={50} />
          <span>● ● ●</span>
          <Typing.Reset count={1} delay={50} />
        </Typing>
      </DiscordMessage>,
      <DiscordMessage profile="bot">
      	<DiscordEmbed slot="embeds">
          The position is from the game <a href="https://www.chess.com/games/search?opening=&openingId=&p1=&p2=&mr=&lsty=1&year=&lstMoves=1&moves=&fen=2R5/4bppk/1p1p4/5R1P/4PQ2/5P2/r4q1P/7K+w+-+-+0+1&ref_id=23962172">Magnus Carlsen (2853) vs. Sergey Karjakin (2772), 2016.</a>
          <Spoiler>White won in 50 moves.</Spoiler>
      		<DiscordEmbedFields slot="fields">
            <DiscordEmbedField fieldTitle="Hints" inline={true}>
      				Piece: <Spoiler>Queen</Spoiler> Move: <Spoiler>Qh6+</Spoiler>
      			</DiscordEmbedField>
            <DiscordEmbedField fieldTitle="Evaluation" inline={true}>
      				<Spoiler>White has mate in 2</Spoiler>
      			</DiscordEmbedField>
            <DiscordEmbedField fieldTitle="Best continuation" inline={false}>
      				<Spoiler>Qh6+ Kxh6 Rh8#</Spoiler>
      			</DiscordEmbedField>
            <DiscordEmbedField fieldTitle="Analyze on" inline={true}>
      				<a href="https://chess.com/analysis?fen=2R5/4bppk/1p1p4/5R1P/4PQ2/5P2/r4q1P/7K+w+-+-+0+1&flip=false&ref_id=23962172">chess.com</a>
      			</DiscordEmbedField>
            <DiscordEmbedField fieldTitle="Analyze on" inline={true}>
      				<a href="https://lichess.org/analysis/2R5/4bppk/1p1p4/5R1P/4PQ2/5P2/r4q1P/7K_w_-_-_0_1">lichess.org</a>
      			</DiscordEmbedField>
      		</DiscordEmbedFields>
      	</DiscordEmbed>
      </DiscordMessage>,
    ];
  }

  advance(delay = 1000) {
    setTimeout(() => {
      const { drawn } = this.state;
      if (drawn < this.messages.length) {
        this.setState({
          drawn: drawn + 1,
        });
      }
    }, delay);
  }

  render() {
    const { classes } = this.props;
    const { drawn } = this.state;

  	return (
      <MuiThemeProvider theme={theme}>
        <Container maxWidth="md" className={classes.container}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <div className={classes.desc}>
                <Typography variant="h2" color="secondary"><a href="https://chessvision.ai" rel="noreferrer noopener" target="_blank" className={classes.homeLink}>Chessvision.ai</a> Discord Bot</Typography>
                <Typography color="inherit" variant="body2" component="ul" className={classes.featuresList}>
                    <li>Recognizes chess positions from images</li>
                    <li>Provides engine evaluation, hints, and links to external analysis</li>
                    <li>Finds games the positions come from</li>
                </Typography>
                <Button
                  target="_blank"
                  href="https://discord.com/oauth2/authorize?client_id=798195105927528569&scope=bot&permissions=84992"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  className={classes.cta}>
                  <span className={classes.ctaContent}>
                    <InlineIcon icon={discordIcon} width="2.5rem" />
                    <span className={classes.ctaText}>Install Bot </span>
                  </span>
                </Button>
                <a href="https://discord.gg/zkcBPhWhme" rel="noreferrer noopener" target="_blank" className={classes.discordLink}>Join our Discord</a>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.discordContainer}>
                <div className={classes.discordHeader}>
                  <span className="mac-circle bg-danger"></span>
                  <span className="mac-circle bg-warning"></span>
                  <span className="mac-circle bg-success"></span>
                </div>
                <div style={{ marginTop: `${25-130*Math.max(0, drawn-4)}px`}}>
                  <DiscordOptionsContext.Provider value={discordOptions}>
                    <DiscordMessages>
                      { this.messages.slice(0, drawn)
                        .filter((m, i) => (drawn < this.messages.length || i !== this.messages.length - 2))
                        .map((m, i) => {
                          return <React.Fragment key={m}>{m}</React.Fragment>;
                        })
                      }
                    </DiscordMessages>
                  </DiscordOptionsContext.Provider>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Newsletter />
        <Footer />
      </MuiThemeProvider>
  	);
  }
}

export default withStyles(styles)(App);
