import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import deepOrange from '@material-ui/core/colors/deepOrange';

const chessvisionPrimaryColor = {
  main: '#1d0954',
  light: '#2E186D',
};

const chessvisionSecondaryColor = deepOrange;

const initialPalette = {
  primary: chessvisionPrimaryColor,
  secondary: chessvisionSecondaryColor,
  background: grey,
};

let theme = createMuiTheme({
  palette: initialPalette,
  typography: {
    h1: {
      fontSize: '3rem',
      color: grey[900],
      fontWeight: 800,
      letterSpacing: '-0.025em',
    },
    h2: {
      fontSize: '2.3rem',
      color: grey[900],
      fontWeight: 800,
      letterSpacing: '-0.025em',
    },
    h4: {
      fontSize: '1.125rem',
      textTransform: 'uppercase',
      fontWeight: 600,
      letterSpacing: '0.05em',
    },
    body2: {
      fontSize: '1.25rem',
      maxWidth: '48rem',
      color: grey[500],
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '1.25rem',
    },
  }
});

theme = responsiveFontSizes(theme);

export default theme;
